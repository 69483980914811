<template>
  <div class="grid" :class="`${catalogTab}-grid`">
    <div id="checkbox"></div>
    <div id="preview" v-if="allowed.includes('preview')"></div>
    <!-- status -->
    <el-dropdown
      id="status"
      placement="bottom"
      trigger="click"
      class="filter-dropdown"
      v-if="allowed.includes('status')"
    >
      <div class="filter-container">
        <span>{{ filters.status }}</span>
        <img alt="img" src="@/assets/img/select_arrow.svg" />
      </div>
      <el-dropdown-menu class="filter-dropdown-menu" slot="dropdown">
        <div class="dropdown-menu-conteainer">
          <div
            class="filter-dropdown-item"
            @click="onChangeStatus(value)"
            v-for="(value, key) of statuses"
            :key="key"
          >
            {{ key }}
          </div>
        </div>
      </el-dropdown-menu>
    </el-dropdown>
    <!-- name -->
    <input
      id="name"
      type="text"
      class="filter-container"
      v-model="filters.name"
      @input="onInputName"
      v-if="allowed.includes('name')"
    />
    <!-- categories -->
    <el-dropdown
      id="categories"
      trigger="click"
      class="filter-dropdown"
      placement="bottom"
      v-if="allowed.includes('categories')"
    >
      <div class="filter-container">
        <span>{{ getCtgs(filters.categories) }}</span>
        <img alt="img" src="@/assets/img/select_arrow.svg" class="p-arrow" />
      </div>
      <el-dropdown-menu class="filter-dropdown-menu categories" slot="dropdown">
        <input type="text" class="filter-input" v-model="ctgsFilterInput" />
        <div
          @click="clearCategories"
          class="filter-dropdown-item"
          style="justify-content: center;"
        >
          clear
        </div>
        <div class="dropdown-menu-conteainer">
          <label
            class="checkbox-container filters"
            v-for="(c, idx) of categoriesListMod"
            :key="idx"
          >
            <input
              type="checkbox"
              hidden
              :value="c"
              @change="onChangeCategories"
              v-model="filters.categories"
            />
            <div class="checkbox-item"></div>
            <span>{{ c.name }}</span>
          </label>
        </div>
      </el-dropdown-menu>
    </el-dropdown>
    <!-- size -->
    <el-dropdown
      id="size"
      placement="bottom"
      trigger="click"
      class="filter-dropdown"
      v-if="allowed.includes('size')"
    >
      <div class="filter-container">
        <span>{{ filters.size.join(", ") }}mb</span>
        <img alt="img" src="@/assets/img/select_arrow.svg" />
      </div>
      <el-dropdown-menu class="filter-dropdown-menu" slot="dropdown">
        <el-slider
          class="slider"
          v-model="filters.size"
          range
          :step="0.2"
          :max="10"
          :marks="sizeMarks"
          @change="onChangeSize"
        >
        </el-slider>
      </el-dropdown-menu>
    </el-dropdown>
    <!-- created ad -->
    <input
      v-if="allowed.includes('created at')"
      id="created_at"
      class="filter-container"
      type="date"
      v-model="filters.created_at"
      @change="onChangeCreatedAt"
    />
    <!-- updated ad -->
    <input
      v-if="allowed.includes('updated at')"
      id="updated_at"
      class="filter-container"
      type="date"
      v-model="filters.updated_at"
      @change="onChangeUpdatedAt"
    />
    <!-- content manager -->
    <input
      v-if="allowed.includes('content manager')"
      id="content_manager"
      class="filter-container"
      type="input"
      v-model="filters.contentManager"
      @input="onChangeContentManager"
    />
    <!-- rating -->
    <el-dropdown
      id="rating"
      placement="bottom"
      trigger="click"
      class="filter-dropdown"
      v-if="allowed.includes('rating')"
    >
      <div class="filter-container">
        <span>{{ filters.rating }}</span>
        <img alt="img" src="@/assets/img/select_arrow.svg" />
      </div>
      <el-dropdown-menu class="filter-dropdown-menu" slot="dropdown">
        <div class="dropdown-menu-conteainer">
          <div
            class="filter-dropdown-item"
            @click="onChangeRating(value)"
            v-for="(value, key) of ratings"
            :key="key"
          >
            {{ key }}
          </div>
        </div>
      </el-dropdown-menu>
    </el-dropdown>
    <!-- author -->
    <input
      v-if="allowed.includes('author')"
      id="author"
      class="filter-container"
      type="text"
      v-model="filters.author"
      @input="onChangeAuthor"
    />
    <!-- set -->
    <el-dropdown
      id="set"
      placement="bottom"
      trigger="click"
      class="filter-dropdown"
      v-if="allowed.includes('set')"
    >
      <div class="filter-container">
        <span>{{ filters.set }}</span>
        <img alt="img" src="@/assets/img/select_arrow.svg" />
      </div>
      <el-dropdown-menu class="filter-dropdown-menu" slot="dropdown">
        <div class="dropdown-menu-conteainer">
          <div
            class="filter-dropdown-item"
            @click="onChangeSet(s)"
            v-for="s of [{ name: '--no--', id: null }, ...allInteractions]"
            :key="s.id"
          >
            {{ s.name }}
          </div>
        </div>
      </el-dropdown-menu>
    </el-dropdown>
    <!-- visibilyty -->
    <!-- set -->
    <el-dropdown
      id="visibility"
      placement="bottom"
      trigger="click"
      class="filter-dropdown"
      v-if="allowed.includes('visibility')"
    >
      <div class="filter-container">
        <span>{{ filters.visibility }}</span>
        <img alt="img" src="@/assets/img/select_arrow.svg" />
      </div>
      <el-dropdown-menu class="filter-dropdown-menu" slot="dropdown">
        <div class="dropdown-menu-conteainer">
          <div
            class="filter-dropdown-item"
            @click="onChangeVisibility(v)"
            v-for="v of ['--no--', 'visible', 'hidden']"
            :key="v"
          >
            {{ v }}
          </div>
        </div>
      </el-dropdown-menu>
    </el-dropdown>
    <!-- users -->
    <!-- fullname -->
    <input
      v-if="allowed.includes('fullname')"
      id="fullname"
      class="filter-container"
      type="text"
      v-model="filters.fullname"
      @input="onChangeFullname"
    />
    <!-- email -->
    <input
      v-if="allowed.includes('email')"
      id="email"
      class="filter-container"
      type="text"
      v-model="filters.email"
      @input="onChangeEmail"
    />
    <!-- email -->
    <input
      v-if="allowed.includes('user_email')"
      id="email"
      class="filter-container"
      type="text"
      v-model="filters.email"
      @input="onChangeUserEmail"
    />
    <!-- role -->
    <el-dropdown
      id="role"
      placement="bottom"
      trigger="click"
      class="filter-dropdown"
      v-if="allowed.includes('role')"
    >
      <div class="filter-container">
        <span>{{ filters.role }}</span>
        <img alt="img" src="@/assets/img/select_arrow.svg" />
      </div>
      <el-dropdown-menu class="filter-dropdown-menu" slot="dropdown">
        <div class="dropdown-menu-conteainer">
          <div
            class="filter-dropdown-item"
            @click="onChangeRole(value)"
            v-for="(value, key) of userRoles"
            :key="key"
          >
            {{ key }}
          </div>
        </div>
      </el-dropdown-menu>
    </el-dropdown>
    <!-- limits -->
    <div v-if="allowed.includes('limits')" id="limits" class="filter-container">
      <input
        class="filter-container"
        type="number"
        v-model.number="filters.limits"
        @input="onChangeLimits"
      />Mb
    </div>
    <!-- active -->
    <el-dropdown
      id="active"
      placement="bottom"
      trigger="click"
      class="filter-dropdown"
      v-if="allowed.includes('active')"
    >
      <div class="filter-container">
        <span>{{ filters.active }}</span>
        <img alt="img" src="@/assets/img/select_arrow.svg" />
      </div>
      <el-dropdown-menu class="filter-dropdown-menu" slot="dropdown">
        <div class="dropdown-menu-conteainer">
          <div
            class="filter-dropdown-item"
            @click="onChangeActive(value)"
            v-for="(value, key) of activeStatus"
            :key="key"
          >
            {{ key }}
          </div>
        </div>
      </el-dropdown-menu>
    </el-dropdown>
    <!-- confirmed_email -->
    <el-dropdown
      id="confirmed_email"
      placement="bottom"
      trigger="click"
      class="filter-dropdown"
      v-if="allowed.includes('confirmed email')"
    >
      <div class="filter-container">
        <span>{{ filters.confirmedEmail }}</span>
        <img alt="img" src="@/assets/img/select_arrow.svg" />
      </div>
      <el-dropdown-menu class="filter-dropdown-menu" slot="dropdown">
        <div class="dropdown-menu-conteainer">
          <div
            class="filter-dropdown-item"
            @click="onChangeConfirmedEmail(value)"
            v-for="(value, key) of confirmedEmailStatuses"
            :key="key"
          >
            {{ key }}
          </div>
        </div>
      </el-dropdown-menu>
    </el-dropdown>
    <!-- sign_up -->
    <el-dropdown
      id="sign_up"
      trigger="click"
      class="filter-dropdown"
      placement="bottom"
      v-if="allowed.includes('sign up')"
    >
      <div class="filter-container">
        <span>{{ filters.signUp }}</span>
        <img alt="img" src="@/assets/img/select_arrow.svg" class="p-arrow" />
      </div>
      <el-dropdown-menu class="filter-dropdown-menu categories" slot="dropdown">
        <div
          @click="onChangeSignUp('none')"
          class="filter-dropdown-item"
          style="justify-content: center;"
        >
          -- none --
        </div>
        <input
          type="date"
          class="filter-input"
          @input="onChangeSignUp()"
          v-model="filters.signUp"
        />
      </el-dropdown-menu>
    </el-dropdown>
    <!-- sign_in -->
    <el-dropdown
      id="sign_in"
      trigger="click"
      class="filter-dropdown"
      placement="bottom"
      v-if="allowed.includes('last sign in')"
    >
      <div class="filter-container">
        <span>{{ filters.signIn }}</span>
        <img alt="img" src="@/assets/img/select_arrow.svg" class="p-arrow" />
      </div>
      <el-dropdown-menu class="filter-dropdown-menu categories" slot="dropdown">
        <div
          @click="onChangeSignIn('none')"
          class="filter-dropdown-item"
          style="justify-content: center;"
        >
          -- none --
        </div>
        <div
          @click="onChangeSignIn('nonce')"
          class="filter-dropdown-item"
          style="justify-content: center;"
        >
          -- nonce --
        </div>
        <input
          type="date"
          class="filter-input"
          @input="onChangeSignIn()"
          v-model="filters.signIn"
        />
      </el-dropdown-menu>
    </el-dropdown>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "PilCatalogFilters",
  props: {
    catalogTab: {
      require: true,
      type: String
    }
  },
  computed: {
    ...mapGetters({
      allCategories: "contributions/allCategories",
      allInteractions: "contributions/allInteractions"
    }),
    categoriesListMod() {
      if (!this.ctgsFilterInput)
        return [{ name: "--no--", id: null }, ...this.allCategories];
      const filterdCategpries = this.allCategories.filter(c =>
        c.name.includes(this.ctgsFilterInput)
      );
      return [{ name: "--no--", id: null }, ...filterdCategpries];
    },
    allowed() {
      return this.allowedFilters[this.catalogTab];
    }
  },
  mounted() {
    this.$store.dispatch("contributions/getAllCategoriesAndInteractions");
  },
  data() {
    return {
      filters: {
        categories: [],
        status: "a",
        name: "",
        created_at: null,
        updated_at: null,
        size: [0, 10],
        rating: "",
        author: "",
        contentManager: "",
        // users
        fullname: "",
        email: "",
        role: "",
        limits: null,
        active: "",
        confirmedEmail: "",
        signIn: "",
        signUp: "",
        set: "",
        visibility: ""
      },
      credentiala: {},
      ratings: {
        "--no--": "",
        asc: "asc",
        desc: "desc"
      },
      statuses: {
        "--no--": "",
        "on review": "on review",
        validated: "validated",
        rejected: "rejected"
      },
      userRoles: {
        "--no--": "",
        "regular/no-publish": "regular/no-publish",
        regular: "regular",
        editor: "editor"
      },
      activeStatus: {
        "--no--": "",
        active: "active",
        blocked: "blocked"
      },
      confirmedEmailStatuses: {
        "--no--": null,
        true: true,
        false: false
      },
      sortParams: {},
      timeoutId: null,
      ctgsFilterInput: "",
      sizeMarks: {
        0: "0Mb",
        5: "5Mb",
        10: "10Mb"
      },
      allowedFilters: {
        templates: [
          "preview",
          "status",
          "name",
          "categories",
          "size",
          "created at",
          "updated at",
          "content manager",
          "rating",
          "author"
        ],
        images: [
          "preview",
          "status",
          "name",
          "categories",
          "size",
          "created at",
          "updated at",
          "content manager",
          "rating",
          "author"
        ],
        texts: [
          "preview",
          "status",
          "name",
          "categories",
          "size",
          "created at",
          "updated at",
          "content manager",
          "rating",
          "author"
        ],
        pages: [
          "preview",
          "name",
          "visibility",
          "set",
          "size",
          "created at",
          "updated at",
          "content manager",
          "rating"
        ],
        fonts: [
          "status",
          "name",
          "size",
          "created at",
          "author",
          "content manager",
          "rating"
        ],
        users: [
          "fullname",
          "email",
          "role",
          "limits",
          "active",
          "last sign in",
          "sign up",
          "confirmed email"
        ],
        interactions: ["name", "created at", "content manager"],
        feedbacks: ["user_email", "created at"]
      }
    };
  },
  methods: {
    onChangeStatus(value) {
      this.$store.commit("catalog/updateCheckedItems", []);
      this.filters.status = value;
      this.credentiala.status = value ? { _eq: value } : {};
      this.$emit("refresh-filters", this.credentiala);
    },
    onInputName() {
      if (this.timeoutId) clearTimeout(this.timeoutId);
      this.timeoutId = setTimeout(() => {
        this.credentiala.name = this.filters.name
          ? { _ilike: `%${this.filters.name}%` }
          : {};
        this.$emit("refresh-filters", this.credentiala);
      }, 1500);
    },
    clearCategories() {
      this.$store.commit("catalog/updateCheckedItems", []);
      this.filters.categories = [];
      this.onChangeCategories();
    },
    onChangeContentManager() {
      this.$store.commit("catalog/updateCheckedItems", []);
      if (this.timeoutId) clearTimeout(this.timeoutId);
      this.timeoutId = setTimeout(() => {
        if (this.filters.contentManager) {
          this.credentiala.content_manager = {
            email: { _ilike: `%${this.filters.contentManager}%` }
          };
        } else {
          delete this.credentiala.content_manager;
        }
        this.$emit("refresh-filters", this.credentiala);
      }, 1500);
    },
    onChangeRating(value) {
      this.$store.commit("catalog/updateCheckedItems", []);
      this.filters.rating = value;
      this.sortParams = value
        ? { rating: value }
        : { name: "asc", created_at: "desc" };
      this.$emit("refresh-sort-params", this.sortParams);
    },
    onChangeAuthor() {
      this.$store.commit("catalog/updateCheckedItems", []);
      if (this.timeoutId) clearTimeout(this.timeoutId);
      this.timeoutId = setTimeout(() => {
        if (this.filters.author) {
          this.credentiala.author = {
            email: { _ilike: `%${this.filters.author}%` }
          };
        } else {
          delete this.credentiala.author;
        }
        this.$emit("refresh-filters", this.credentiala);
      }, 1500);
    },
    onChangeCategories() {
      this.$store.commit("catalog/updateCheckedItems", []);
      let withOutCategory = false;
      const idsList = this.filters.categories
        .filter(c => {
          if (!c.id) withOutCategory = true;
          return c.id;
        })
        ?.map?.(i => ({ categories: { id: { _eq: i.id } } }));
      if (!idsList.length) delete this.credentiala._and;
      if (!withOutCategory) delete this.credentiala._not;
      if (withOutCategory) this.credentiala._not = { categories: {} };
      if (idsList.length) this.credentiala._and = idsList;
      this.$emit("refresh-filters", this.credentiala);
    },
    getSizeInMBs(size) {
      if (size < 1024) return `${size}bits`;
      if (size < 10240) return `${(size / 1024).toFixed(2)}Kb`;
      return `${(size / 1048576).toFixed(2)}Mb`;
    },
    onChangeVisibility(value) {
      this.$store.commit("catalog/updateCheckedItems", []);
      this.filters.visibility = value;
      console.log(value);
      let visibility = null;
      if (value === "visible") visibility = true;
      if (value === "hidden") visibility = false;
      this.credentiala.visibility = { _eq: visibility };
      this.$emit("refresh-filters", this.credentiala);
    },
    onChangeSet(value) {
      this.$store.commit("catalog/updateCheckedItems", []);
      if (!value.id) {
        delete this.credentiala.interaction_id;
        this.filters.set = "";
        this.$emit("refresh-filters", this.credentiala);
        return;
      }
      this.filters.set = value?.name;
      this.credentiala.interaction_id = { _eq: value.id };
      this.$emit("refresh-filters", this.credentiala);
    },
    onChangeCreatedAt() {
      this.$store.commit("catalog/updateCheckedItems", []);
      if (!this.filters.created_at) {
        delete this.credentiala.created_at;
        this.$emit("refresh-filters", this.credentiala);
        return;
      }
      const oneDay = 1000 * 60 * 60 * 24;
      this.credentiala.created_at = {
        _gte: this.filters.created_at,
        _lt: new Date(
          new Date(this.filters.created_at).getTime() + oneDay
        ).toISOString()
      };
      this.$emit("refresh-filters", this.credentiala);
    },
    onChangeUpdatedAt() {
      this.$store.commit("catalog/updateCheckedItems", []);
      if (!this.filters.updated_at) {
        delete this.credentiala.updated_at;
        this.$emit("refresh-filters", this.credentiala);
        return;
      }
      const oneDay = 1000 * 60 * 60 * 24;
      this.credentiala.updated_at = {
        _gte: this.filters.updated_at,
        _lte: new Date(
          new Date(this.filters.updated_at).getTime() + oneDay
        ).toISOString()
      };
      this.$emit("refresh-filters", this.credentiala);
    },
    onChangeSize() {
      this.$store.commit("catalog/updateCheckedItems", []);
      this.filters.size;
      this.credentiala.size = {
        _gte: Math.round(this.filters.size[0] * 1024 * 1024),
        _lte: Math.round(this.filters.size[1] * 1024 * 1024)
      };
      this.$emit("refresh-filters", this.credentiala);
    },
    getCtgs(ctgsList) {
      if (!ctgsList) return;
      return ctgsList.map(c => c.name).join(", ");
    },
    onChangeFullname() {
      this.$store.commit("catalog/updateCheckedItems", []);
      if (this.timeoutId) clearTimeout(this.timeoutId);
      this.timeoutId = setTimeout(() => {
        this.credentiala.name = this.filters.fullname
          ? { _ilike: `%${this.filters.fullname}%` }
          : {};
        this.$emit("refresh-filters", this.credentiala);
      }, 1500);
    },
    onChangeUserEmail() {
      this.$store.commit("catalog/updateCheckedItems", []);
      if (this.timeoutId) clearTimeout(this.timeoutId);
      this.timeoutId = setTimeout(() => {
        this.credentiala.user_email = this.filters.email
          ? { _ilike: `%${this.filters.email}%` }
          : {};
        this.$emit("refresh-filters", this.credentiala);
      }, 1500);
    },
    onChangeEmail() {
      this.$store.commit("catalog/updateCheckedItems", []);
      if (this.timeoutId) clearTimeout(this.timeoutId);
      this.timeoutId = setTimeout(() => {
        this.credentiala.email = this.filters.email
          ? { _ilike: `%${this.filters.email}%` }
          : {};
        this.$emit("refresh-filters", this.credentiala);
      }, 1500);
    },
    onChangeRole(value) {
      this.$store.commit("catalog/updateCheckedItems", []);
      this.credentiala.role = value ? { _eq: value } : {};
      this.$emit("refresh-filters", this.credentiala);
    },
    onChangeLimits() {
      this.$store.commit("catalog/updateCheckedItems", []);
      if (this.timeoutId) clearTimeout(this.timeoutId);
      this.timeoutId = setTimeout(() => {
        const defaultMomoryLimit = 10;
        delete this.credentiala.limits;
        delete this.credentiala._or;
        if (typeof this.filters.limits === "string") {
          this.$emit("refresh-filters", this.credentiala);
          return;
        } else if (this.filters.limits === defaultMomoryLimit) {
          this.credentiala = {
            _or: [
              { _not: { limits: { _has_key: "storage" } } },
              { limits: { _contains: { storage: defaultMomoryLimit } } }
            ]
          };
        } else
          this.credentiala.limits = {
            _contains: { storage: this.filters.limits }
          };
        this.$emit("refresh-filters", this.credentiala);
      }, 1500);
    },
    onChangeActive(value) {
      this.$store.commit("catalog/updateCheckedItems", []);
      this.credentiala.active = value ? { _eq: value } : {};
      this.$emit("refresh-filters", this.credentiala);
    },
    onChangeConfirmedEmail(value) {
      this.$store.commit("catalog/updateCheckedItems", []);
      if (value === null) delete this.credentiala.confirm_email;
      else this.credentiala.confirm_email = { _eq: value };
      this.$emit("refresh-filters", this.credentiala);
    },
    onChangeSignUp(action) {
      this.$store.commit("catalog/updateCheckedItems", []);
      if (action === "none" || !this.filters.signUp) {
        this.filters.signUp = "";
        delete this.credentiala.created_at;
        this.$emit("refresh-filters", this.credentiala);
        return;
      }
      const oneDay = 1000 * 60 * 60 * 24;
      this.credentiala.created_at = {
        _gte: this.filters.signUp,
        _lte: new Date(
          new Date(this.filters.signUp).getTime() + oneDay
        ).toISOString()
      };
      this.$emit("refresh-filters", this.credentiala);
    },
    onChangeSignIn(action) {
      this.$store.commit("catalog/updateCheckedItems", []);
      if (action === "none" || !this.filters.signIn) {
        this.filters.signIn = "";
        delete this.credentiala.last_signin;
      } else if (action === "nonce") {
        this.filters.signIn = "";
        this.credentiala.last_signin = { _is_null: true };
      } else {
        const oneDay = 1000 * 60 * 60 * 24;
        this.credentiala.last_signin = {
          _gte: this.filters.signIn,
          _lte: new Date(
            new Date(this.filters.signIn).getTime() + oneDay
          ).toISOString()
        };
      }
      this.$emit("refresh-filters", this.credentiala);
    }
  }
};
</script>

<style scoped>
/* ? grid naming */
#checkbox {
  grid-area: ch;
}
#status {
  grid-area: st;
}
#name {
  grid-area: nm;
}
#preview {
  grid-area: pr;
}
#categories {
  grid-area: ct;
}
#size {
  grid-area: sz;
}
#created_at {
  grid-area: cra;
}
#updated_at {
  grid-area: upa;
}
#content_manager {
  grid-area: cm;
}
#author {
  grid-area: aut;
}
#rating {
  grid-area: rt;
}
#set {
  grid-area: set;
}
#fullname {
  grid-area: fn;
}
#email {
  grid-area: em;
}
#role {
  grid-area: ut;
}
#limits {
  grid-area: li;
}
#active {
  grid-area: ac;
}
#sign_up {
  grid-area: sup;
}
#sign_in {
  grid-area: sin;
}
#confirmed_email {
  grid-area: ce;
}
#visibility {
  grid-area: vis;
}
/* grids */
.grid {
  margin-left: 55px;
  margin-bottom: 8px;
  display: grid;
  white-space: nowrap;
  align-items: center;
  justify-items: center;
  min-width: fit-content;
  width: 100%;
}
.templates-grid {
  grid-template-areas: "pr st nm ct sz cra upa cm rt aut ch";
  grid-template-columns:
    minmax(100px, 100fr)
    minmax(120px, 120fr)
    minmax(150px, 150fr)
    minmax(250px, 250fr)
    minmax(70px, 70fr)
    minmax(100px, 100fr)
    minmax(100px, 100fr)
    minmax(200px, 200fr)
    /* minmax(150px, auto) */
    minmax(150px, 150fr)
    minmax(200px, 200fr)
    minmax(300px, 300fr);
  grid-gap: 1vw;
}
.images-grid {
  grid-template-areas: "pr st nm ct sz cra upa cm rt aut ch";
  grid-template-columns:
    minmax(100px, 100fr)
    minmax(120px, 120fr)
    minmax(150px, 150fr)
    minmax(250px, 250fr)
    minmax(70px, 70fr)
    minmax(100px, 100fr)
    minmax(100px, 100fr)
    minmax(200px, 200fr)
    minmax(150px, 150fr)
    minmax(200px, 200fr)
    minmax(300px, 200fr);
}
.texts-grid {
  grid-template-areas: "pr st nm ct sz cra upa cm rt aut ch";
  grid-template-columns:
    minmax(100px, 100fr)
    minmax(120px, 120fr)
    minmax(150px, 150fr)
    minmax(250px, 250fr)
    minmax(70px, 70fr)
    minmax(100px, 100fr)
    minmax(100px, 100fr)
    minmax(200px, 200fr)
    /* minmax(150px, auto) */
    minmax(150px, 150fr)
    minmax(200px, 200fr)
    minmax(300px, 300fr);
}
.fonts-grid {
  grid-template-areas: "st nm sz cra rt aut cm ch";
  grid-template-columns:
    minmax(120px, 120fr)
    minmax(150px, 150fr)
    minmax(70px, 70fr)
    minmax(100px, 100fr)
    minmax(200px, 200fr)
    minmax(200px, 200fr)
    minmax(200px, 200fr)
    minmax(150px, 150fr)
    minmax(300px, 300fr);
}
.pages-grid {
  grid-template-areas: "pr nm vis set sz cra upa cm rt ch";
  grid-template-columns:
    minmax(100px, 100fr)
    minmax(150px, 150fr)
    minmax(70px, 70fr)
    minmax(100px, 100fr)
    minmax(100px, 100fr)
    minmax(200px, 200fr)
    minmax(150px, 150fr)
    minmax(70px, 70fr);
}
.users-grid {
  grid-template-areas: "pr fn em ut li ac sup sin ce ch";
  grid-template-columns:
    minmax(100px, auto)
    minmax(300px, auto)
    minmax(300px, auto)
    minmax(150px, auto)
    minmax(130px, auto)
    minmax(100px, auto)
    minmax(130px, auto)
    minmax(130px, auto)
    minmax(100px, auto);
  grid-gap: 1vw;
}
.interactions-grid {
  grid-template-areas: "pr nm emp emp cra cm ch";
  grid-template-columns:
    minmax(70px, 70fr)
    minmax(150px, 150fr)
    minmax(500px, 500fr)
    minmax(150px, 150fr)
    minmax(150px, 150fr);
  grid-gap: 1vw;
}
.feedbacks-grid {
  grid-template-areas: "em cra ch ch ch ch ch";
  grid-template-columns:
    minmax(150px, 150fr)
    minmax(120px, 120fr)
    minmax(70px, 70fr)
    minmax(400px, 400fr)
    minmax(400px, 400fr);
}
.dropdown-menu-conteainer {
  max-height: 200px;
  overflow: auto;
}
.filter-dropdown {
  width: 100%;
}

.filter-input,
.filter-container {
  display: flex;
  width: 100%;
  height: 25px;
  box-sizing: border-box;
  background: #f8f8f5;
  border-radius: 7px;
  border: none;
  font-size: 14px;
  line-height: 25px;
  display: flex;
  justify-content: space-between;
  padding: 0 5px;
}
.filter-input {
  margin: 5px 10px 0;
  width: calc(100% - 20px);
}
.filter-container span {
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: calc(100% - 10px);
  overflow: hidden;
}
.filter-dropdown-menu {
  border-radius: 7px;
}
.filter-dropdown-menu.categories {
  width: 250px;
  padding: 0;
}
.filter-container img {
  max-height: 5px;
  margin: auto 0;
}
.filter-dropdown-item {
  align-items: center;
  text-align: center;
  padding: 0 15px;
  font-size: 12px;
  line-height: 20px;
  cursor: pointer;
  display: flex;
}
.filter-dropdown-item:hover {
  background: #f8f8f5;
}
.slider {
  min-width: 100px;
  padding: 0 20px;
}
.slider >>> .el-slider__runway {
  margin: 5px;
}
.slider >>> .el-slider__marks {
  display: flex;
  justify-content: space-between;
  padding-top: 10px;
  width: 320px;
  font-size: 10px;
}
</style>
